<template>
  <div class="base-pagination">
    <div class="base-pagination__inner">
      <div class="base-pagination__btns">
        <button
          v-for="i in pagesCount"
          :key="i"
          :class="{ ['base-pagination__btn--active']: currentPage === i }"
          class="base-pagination__btn"
          @click="setPage(i)"
        >
          {{ i }}
        </button>
      </div>
      <div class="base-pagination__select-wrapper">
        <div class="base-pagination__select-label standart-title">
          {{ $t('label') }}
        </div>
        <base-select
          :value="step"
          :searchable="false"
          :options="steps"
          :custom-label="name"
          :allow-empty="false"
          :show-labels="false"
          size="small"
          class="base-pagination__select"
          @input="onChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/Select/BaseSelect';
export default {
  name: 'BasePagination',
  components: { BaseSelect },
  props: {
    step: {
      type: [Number, String],
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    start: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  computed: {
    pagesCount() {
      if (this.step === 'all') return 1;
      return Math.ceil(this.count / this.step);
    },
    currentPage() {
      if (this.step === 'all') return 1;
      let n = this.start >= this.step ? Math.ceil((this.start + 1) / this.step) : 1;
      if (n > this.pagesCount) n = this.pagesCount;
      return n;
    },
  },
  methods: {
    name(value) {
      return value === 'all' ? this.$t('all') : value;
    },
    onChange(value) {
      console.log(value);
      this.$emit('step-change', value);
      this.$nextTick(() => this.setPage(this.currentPage));
    },
    setPage(value) {
      console.log(value);
      this.$emit('page-change', value);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "label": "Показывать на странице",
      "all": "все"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-pagination {

  &__inner {
    margin: -0.5rem;

    +breakpoint(sm-and-up) {
      flexy(flex-start, center, wrap);
    }
  }

  &__btns {
    flexy(flex-start, center, wrap);
    +breakpoint(sm-and-up) {
      margin-right: 7.5rem;
    }
  }

  &__btn {
    display: block;
    flex: 0 0 auto;
    size(2.5rem);
    border-radius: $border-radius-smaller;
    border: 1px solid var(--input-border);
    background: transparent;
    color: var(--text);
    transition: color $base-anim-duration ease, background $base-anim-duration ease;
    box-shadow: none;
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    cursor: pointer;
    margin: 0.5rem;

    &:hover,
    &:focus {
      outline: none;
    }

    &--active {
      pointer-events: none;
      background: var(--input-border);
    }
  }

  &__select {
    width: 70px;
    cursor: pointer;

    &-label {
      margin-right: 1rem;
    }

    &-wrapper {
      flexy(flex-start, center);
      margin: 0.5rem;
    }
  }
}
</style>
