var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-table-row',_vm._b({staticClass:"tariffs-table-item",attrs:{"columns":_vm.columns,"col-class":"tariffs-table-item__col"},scopedSlots:_vm._u([{key:"name",fn:function(){return [_vm._v(" "+_vm._s(_vm.item.access)+" "),_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: `Скопировать в буфер обмена`,
        placement: 'bottom-start',
        container: 'body',
      }),expression:"{\n        content: `Скопировать в буфер обмена`,\n        placement: 'bottom-start',\n        container: 'body',\n      }"}],staticClass:"password__field",attrs:{"icon":"copy-gray","size":"small","color":"dim","tabindex":"-1"},on:{"click":function($event){return _vm.copyToClipboard('Access')}}})]},proxy:true},{key:"id",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__ip"},[_vm._v(" "+_vm._s(_vm.item.secret)+" "),_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: `Скопировать в буфер обмена`,
          placement: 'bottom-start',
          container: 'body',
        }),expression:"{\n          content: `Скопировать в буфер обмена`,\n          placement: 'bottom-start',\n          container: 'body',\n        }"}],staticClass:"password__field",attrs:{"icon":"copy-gray","size":"small","color":"dim","tabindex":"-1"},on:{"click":function($event){return _vm.copyToClipboard('Secret')}}})],1)]},proxy:true},(_vm.item.description)?{key:"descr",fn:function(){return [_vm._v(" "+_vm._s(_vm.item?.description)+" ")]},proxy:true}:null,{key:"more",fn:function(){return [_c('div',{staticClass:"more"},[_c('Button',{staticClass:"p-button-rounded p-button-text",attrs:{"type":"linc","icon":"pi pi-file-edit"},on:{"click":function($event){return _vm.changeS3KeyDescription(_vm.item, _vm.id)}}}),_c('plain-button',{staticClass:"tariffs-table-item__more",attrs:{"icon":"delete","color":"del"},on:{"click":function($event){return _vm.deleteS3Key(_vm.lastKey)}}})],1)]},proxy:true}],null,true)},'base-table-row',_vm.item,false))
}
var staticRenderFns = []

export { render, staticRenderFns }