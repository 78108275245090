<template>
  <div class="tariffs-table">
    <base-table :columns="tableHead" :list="paginList" class="tariffs-table__table">
      <tariffs-table-s3-keys-row
        v-for="item in paginList"
        :id="id"
        :key="item.id"
        :last-key="isLastKey"
        :columns="tableHead"
        :item="item"
        @toast="sentToast"
      />
      <!--        @click-more="$emit('click-more', item)"-->
    </base-table>
    <base-pagination
      v-if="paginList.length !== s3Keys.length"
      :step="pagin.step"
      :steps="stepOptions"
      :count="s3Keys.length"
      :start="pagin.start"
      class="tariffs-table__pagination"
      @step-change="onStepChange"
      @page-change="onPageChange"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import BasePagination from '@/components/Pagination/BasePagination';
import TariffsTableS3KeysRow from '@/layouts/Stack/pages/Main/components/TariffsTableS3KeysRow';

export default {
  name: 'TariffsTableS3Keys',
  components: {
    TariffsTableS3KeysRow,
    BaseTable,
    BasePagination,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '200px',
            maxWidth: '210px',
          },
        },
        {
          key: 'id',
          label: this.$t('tableHead.id'),
          style: {
            width: '200px',
            maxWidth: '210px',
            // paddingRight: '220px',
            // textAlign: 'right',
          },
        },
        {
          key: 'descr',
          label: this.$t('tableHead.descr'),
          style: {
            width: '200px',
            // textAlign: 'center',
            maxWidth: '240px',
          },
        },
        {
          key: 'more',
          style: {
            width: '40px',
            textAlign: 'right',
            maxWidth: '40px',
          },
        },
      ],
      stepOptions: [10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 10,
      },
    };
  },
  computed: {
    s3Keys() {
      return this.$store.state.moduleStack.s3Keys;
    },
    paginList() {
      if (this.pagin.step === 'all') return this.s3Keys;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.s3Keys.slice(start, end);
    },
    isLastKey() {
      return this.s3Keys.length === 1;
    },
  },
  methods: {
    onStepChange(value) {
      this.pagin.step = value;
    },
    sentToast(value) {
      this.$emit('toast', value);
    },
    onPageChange(value) {
      this.pagin.start = this.pagin.step * (value - 1);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "name": "Access",
      "descr": "Описание",
      "id": "Secret"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
