<template>
  <!--  <div>-->
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      {{ item.access }}
      <plain-button
        v-tooltip="{
          content: `Скопировать в буфер обмена`,
          placement: 'bottom-start',
          container: 'body',
        }"
        icon="copy-gray"
        size="small"
        color="dim"
        tabindex="-1"
        class="password__field"
        @click="copyToClipboard('Access')"
      />
    </template>
    <template #id>
      <div class="tariffs-table-item__ip">
        {{ item.secret }}
        <plain-button
          v-tooltip="{
            content: `Скопировать в буфер обмена`,
            placement: 'bottom-start',
            container: 'body',
          }"
          icon="copy-gray"
          size="small"
          color="dim"
          tabindex="-1"
          class="password__field"
          @click="copyToClipboard('Secret')"
        />
      </div>
    </template>
    <template v-if="item.description" #descr>
      {{ item?.description }}
      <!--      <Button-->
      <!--        type="linc"-->
      <!--        icon="pi pi-file-edit"-->
      <!--        class="p-button-rounded p-button-text"-->
      <!--        @click="changeS3KeyDescription()"-->
      <!--      ></Button>-->
      <!--      <plain-button-->
      <!--          icon="delete"-->
      <!--          color="dim"-->
      <!--          class="tariffs-table-item__more"-->
      <!--          @click="deleteS3Key(lastKey)"-->
      <!--      />-->
    </template>
    <template #more>
      <div class="more">
        <Button
          type="linc"
          icon="pi pi-file-edit"
          class="p-button-rounded p-button-text"
          @click="changeS3KeyDescription(item, id)"
        ></Button>
        <plain-button
          icon="delete"
          color="del"
          class="tariffs-table-item__more"
          @click="deleteS3Key(lastKey)"
        />
      </div>
    </template>
  </base-table-row>
  <!--  </div>-->
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import popoverFix from '@/mixins/popoverFix';
import Button from 'primevue/button';
import BaseLoader from '@/components/BaseLoader/BaseLoader';

import showErrorModal from '@/mixins/showErrorModal';
import changeS3KeyDescription from '@/mixins/changeS3KeyDescription';
export default {
  name: 'TariffsTableS3KeysRow',
  components: {
    BaseTableRow,
    Button,
  },
  mixins: [popoverFix, changeS3KeyDescription, showErrorModal],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    lastKey: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {},
  methods: {
    mouseOver: function () {
      this.active = !this.active;
    },
    deleteCurrentS3Key() {
      return this.$store.dispatch('moduleStack/deleteS3StoragesKey', {
        key: this.item.access,
        id: this.id,
      });
    },
    copyToClipboard(text) {
      this.$clipboard(this.item[text.toLowerCase()]);
      this.$emit('toast', {
        type: 'success',
        title: 'скопирован в буфер обмена',
        item: this.item[text.toLowerCase()],
        details: text,
        life: 3000,
      });
    },
    deleteS3Key(key) {
      const text = key
        ? this.$t('modal.sure.textLast', { msg: this.item.access })
        : this.$t('modal.sure.text', { msg: this.item.access });
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        // text: this.$t('modal.sure.text', { msg: this.item.access }),
        text: text,
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
              color: 'error',
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;

                this.deleteCurrentS3Key()
                  .then(async data => {
                    console.log(data);
                  })
                  .catch(e => this.showError(e));
                const delay = Date.now() - time < 1000 ? 1000 : 0;
                setTimeout(() => {
                  self.component = null;
                  self.closable = true;
                  self.text = this.$t('modal.sure.success');
                  self.footer = {
                    centered: true,
                    confirm: {
                      props: { title: this.$t('modal.sure.close') },
                      on: {
                        click: () => {
                          this.$modals.close();
                          this.$store
                            .dispatch('moduleStack/getS3StoragesKeys', this.id)
                            .then(data => console.log(data));
                        },
                      },
                    },
                  };
                }, delay);
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "actions": {
      "delete": "Удалить S3 ключ"
    },
    "sure": {

      "confirm": "Подтвердить"
    },
    "description": {
      "name": "Описание S3 Ключа:",
      "text" : "Текстовое описание",
      "confirm": "Подтвердить"
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь удалить <b>%{msg}</b> S3 ключ. Вы уверены?",
        "textLast": "Внимание! После удаления последнего ключа, доступ к хранилищу S3 через личный кабинет будет прекращён. <br /> Для восстановления доступа нужно будет создать новый ключ. <br> Удалить ключ S3? ",
        "confirm": "Подтвердить",
        "close": "Закрыть",
        "success": "S3 ключ успешно удалён.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.more {
  display: flex;
  justify-content: end
}
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    &__more {
      margin-left: 0.5rem
    }

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__ip {
  }

  &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>
